<template>
  <div>
    <Breadcrumbs title="Version 1.0" main="Imports"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <b-form-group class="custom-file">
                <b-form-file id="file" name="file" ref="file" @change="handleFileUpload()" placeholder="Type to Search" ></b-form-file>
              </b-form-group>

              <button @click="submitFile()">Submit</button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {checkUserPermissions} from '@/plugins/functions'
import { mapState } from 'vuex'
import axios from "@/plugins/axios";
// import CRUD_API from '@/services/crud'
// import API from '@/services/api'
export default {
  name: "import-v1-index",
  computed: {
    ...mapState({user: state => JSON.parse(state.auth.user)}),
  },
  data() {
    return {
      api_base: '/api/backend/imports/v1',
      file: '',
    }
  },
  methods: {
    checkUserPermissions,
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    submitFile() {
      let formData = new FormData();
      formData.append('file', this.file)
      axios.post( 'api/backend/pages/imports/v1 ', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then((result) => {
        console.log("RES", result);
      })
      .catch((error) => {
        console.log(error)
      });
    }
  }
}
</script>

<style scoped>

</style>
